import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

function getSelectedLocale(location) {
  let selectedLocale = 'ro'; // Default locale: Romanian
  if (location && location.query && location.query.locale) {
    selectedLocale = location.query.locale;
  }
  return selectedLocale;
}

const InlineSVG = props => {
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(
    () => {
      fetch(props.url)
        .then(res => res.text())
        .then(setSvg)
        .catch(setError)
        .then(() => setIsLoaded(true));
    },
    [props.url],
  );
  const loadStatus = isLoaded ? 'loaded' : 'loading';
  const errorStatus = isError ? 'svg--error' : '';
  return (
    <div
      className={`svg svg--${loadStatus} ${errorStatus}`}
      style={props.style}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

InlineSVG.propTypes = {
  url: PropTypes.string.isRequired,
  style: PropTypes.any,
};

const wideStyle = {
  display: 'inline-block',
  margin: '10px',
  padding: '0px',
  width: '120px',
  height: '48px',
};

const playWideStyle = {
  ...wideStyle,
  width: '133px',
  height: '48px',
};

const bannerStyle = {
  margin: '0px',
  padding: '0px',
};

const storeLinksStyle = {
  marginTop: '20px',
};

export function MobileAppDownload(props, { location }) {
  const selectedLocale = getSelectedLocale(location);
  const localizedRecords = {
    ro: { locale: 'ro', title: 'Instaleaza aplicatia' },
    en: { locale: 'en', title: 'Get the cityradar mobile app' },
  };
  const { title } = localizedRecords[selectedLocale];

  return (
    <div style={storeLinksStyle}>
      <div>
        <p>
          <FormattedMessage id="get-mobile-app" defaultMessage={title} />
        </p>
      </div>
      <div className="store-banner" style={wideStyle}>
        <a
          href="https://apps.apple.com/ro/app/cityradar/id1518330471"
          alt="Install Cityradar from Apple AppStore"
        >
          <InlineSVG url="/img/appstore.svg" style={bannerStyle} />
        </a>
      </div>
      <div className="store-banner" style={playWideStyle}>
        <a
          href="https://play.google.com/store/apps/details?id=ro.opentransport.cityradar&hl=en"
          alt="Install Cityradar Android App"
        >
          <InlineSVG url="/img/playstore.svg" style={bannerStyle} />
        </a>
      </div>
    </div>
  );
}

const components = {
  MobileAppDownload,
};
export default components;
